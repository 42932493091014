export class Logger {
    public static debug(...message: any) {
        console.debug(...message);
    }

    public static info(...message: any) {
        console.info(...message);
    }

    public static warn(...message: any) {
        console.warn(...message);
    }

    public static error(...message: any) {
        console.error(...message);
    }
}
