import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Language, LanguageShortCode } from "../language/language";
import { TranslateText, getTranslation } from "../language/translate";
import { ViewCustomMediaErrorStatus } from "../audio/mediaUtilities";

// Navigation
export enum View {
    Loading,
    Welcome,
    Call,
    Status,
    Thanks,
}

export enum UserStatus {
    BrowserNotSupported,
    BrowserNotSupportedUseSafari,
    InvalidLink,
    NotAllowed,
    NetworkConfigurationMissing,
    MicrophonePermissionRequired,

    MediaError,
}

const DEFAULT_NAVIGATION_STATE: ViewState = {
    view: View.Loading,
};

export type ViewState = LoadingViewState | WelcomeViewState | CallViewState | StatusViewState | ThanksViewState;

export interface LoadingViewState {
    view: View.Loading;
}

export interface WelcomeViewState {
    view: View.Welcome;
}

export interface CallViewState {
    view: View.Call;
}

export interface StatusViewState {
    view: View.Status;
    status: UserStatus;
    error?: ViewCustomMediaErrorStatus;
}

export interface ThanksViewState {
    view: View.Thanks;
}

// Context provides a way to pass data through the component tree without having to pass props down manually at every level.

const NavigationContext = createContext<[ViewState, (x: ViewState) => void]>([DEFAULT_NAVIGATION_STATE, () => null]);

// Event mode
const DEFAULT_EVENT_MODE_STATE = false;

const EventModeContext = createContext<[boolean, (x: boolean) => void]>([DEFAULT_EVENT_MODE_STATE, () => null]);

// Advanced mode
const DEFAULT_ADVANCED_STATE = false;

const AdvancedContext = createContext<[boolean, (x: boolean) => void]>([DEFAULT_ADVANCED_STATE, () => null]);

// View only mode
const DEFAULT_VIEWONLY_STATE = false;

const ViewOnlyContext = createContext<[boolean, (x: boolean) => void]>([DEFAULT_VIEWONLY_STATE, () => null]);

// Translation
const DEFAULT_TRANSLATION_STATE = Language.English;

const TranslationContext = createContext<[(translateTextKey: TranslateText) => string, Language, (newLanguage: Language) => void]>([() => "", DEFAULT_TRANSLATION_STATE, () => null]);

interface GlobalStateProps {
    children?: JSX.Element;
}

// Global state component
export const GlobalState: React.FunctionComponent<GlobalStateProps> = (props: GlobalStateProps) => {
    const navigation = useState<ViewState>(DEFAULT_NAVIGATION_STATE);
    const advanced = useState<boolean>(DEFAULT_ADVANCED_STATE);
    const viewOnly = useState<boolean>(DEFAULT_VIEWONLY_STATE);
    const [language, setLanguage] = useState<Language>(DEFAULT_TRANSLATION_STATE);

    const translate = useCallback(
        (message: TranslateText) => {
            return getTranslation(message, language);
        },
        [language],
    );

    useEffect(() => {
        if (language === Language.English) {
            document.documentElement.setAttribute("lang", LanguageShortCode.English);
        } else {
            document.documentElement.setAttribute("lang", LanguageShortCode.Swedish);
        }
    }, [language]);

    return (
        <NavigationContext.Provider value={navigation}>
            <AdvancedContext.Provider value={advanced}>
                <ViewOnlyContext.Provider value={viewOnly}>
                    <TranslationContext.Provider value={[translate, language, setLanguage]}>{props.children}</TranslationContext.Provider>
                </ViewOnlyContext.Provider>
            </AdvancedContext.Provider>
        </NavigationContext.Provider>
    );
};

// Hooks
export const useNavigation = () => useContext(NavigationContext);

export const useEventMode = () => useContext(EventModeContext);

export const useAdvanced = () => useContext(AdvancedContext);

export const useIsViewOnly = () => useContext(AdvancedContext);

export const useTranslation = () => useContext(TranslationContext);
