import { Language } from "./language";

export enum TranslateText {
    Welcome,
    WelcomeWhenYouPressStart,
    WelcomeTipsForBestExperience,
    WelcomeTipUseHeadset,
    WelcomeTipKeepScreenOn,
    WelcomeTipDontLeaveWebPage,
    WelcomeTipActivateDoNotDisturb,
    WelcomeStart,

    WarningUserRefreshingPage,

    CallHangUp,
    CallMute,
    CallUnMute,
    CallOptions,
    CallYouAreFirstHere1,
    CallYouAreFirstHere2,
    CallKeepScreenOn,
    CallInCallWith,
    CallAreYouSureHangUp,
    CallConfirm,
    CallCancel,
    CallConnecting,

    LatchFunction,
    TalkToAll,

    VideoFitButton,

    SessionEnd,
    SessionStopWatching,

    OptionsLanguage,
    OptionsAudioInput,
    OptionsAudioOutput,
    OptionsAudioFeatures,
    OptionsNoOptionsAvailable,

    ThankYouForJoiningSession,
    ThanksReconnect,

    ErrorBrowserNotSupported,
    ErrorInvalidLink,
    ErrorNotAllowed,
    ErrorNetworkConfigurationMissing,
    ErrorMicPermissionRequired,
    ErrorMediaProblem,


    MediaNotFoundError,
    MediaNotReadableError,
    MediaOverconstrainedError,
    MediaNotAllowedError,
    MediaTypeError,
    MediaAbortError,
    MediaSecurityError,
    MediaPermissionDismissedError,
    MediaUnknown,


    ErrorInfoUseSafariInstead,

    ErrorMicAccessFailed,

    ErrorMicAccessChrome1,
    ErrorMicAccessChrome2,
    ErrorMicAccessFireFox1,
    ErrorMicAccessFireFox2,
    ErrorMicAccessFireFox3,
    ErrorMicAccessFireFox4,
    ErrorMicAccesstEdge1,
    ErrorMicAccesstEdge2,
    ErrorMicAccessSafari1,
    ErrorMicAccessSafari2,
    ErrorMicAccessSafari3,

    ErrorMicAccessChromeMobile1,
    ErrorMicAccessChromeMobile2,
    ErrorMicAccessChromeMobile3,
    ErrorMicAccessChromeMobile4,
    ErrorMicAccessSafariMobile1,
    ErrorMicAccessSafariMobile2,
    ErrorMicAccessSamsungInternet1,
    ErrorMicAccessSamsungInternet2,
    ErrorMicAccessSamsungInternet3,
    ErrorMicAccessSamsungInternet4,

    FooterLanguageHeader,
    FooterLanguageDescription,

    FooterServiceHeader,
    FooterServiceDescription,
}

type TextTemplate = { [P in TranslateText]: string };

const sv: TextTemplate = {
    [TranslateText.Welcome]: `Välkommen!`,
    [TranslateText.WelcomeWhenYouPressStart]: `När du trycker starta kommer du att få en fråga om att ge tillåtelse att använda mikrofonen`,
    [TranslateText.WelcomeTipsForBestExperience]: `För bästa upplevelse och ljudkvalité:`,
    [TranslateText.WelcomeTipUseHeadset]: `Använd headset`,
    [TranslateText.WelcomeTipActivateDoNotDisturb]: `Sätt telefonen i "Stör ej"-läge`,
    [TranslateText.WelcomeTipKeepScreenOn]: `Håll skärmen aktiv`,
    [TranslateText.WelcomeTipDontLeaveWebPage]: `Lämna inte webbsidan`,
    [TranslateText.WelcomeStart]: `Starta`,

    [TranslateText.WarningUserRefreshingPage]: `Vill du ladda om sidan och avbryta sessionen?.`,

    [TranslateText.CallHangUp]: `Lägg på`,
    [TranslateText.CallMute]: `Ljud av`,
    [TranslateText.CallUnMute]: `Ljud på`,
    [TranslateText.CallOptions]: `Inställningar`,
    [TranslateText.CallYouAreFirstHere1]: `Just nu är du ensam i sessionen`,
    [TranslateText.CallYouAreFirstHere2]: `Lämna inte sidan när du är i sessionen`,
    [TranslateText.CallInCallWith]: `Aktiva`,
    [TranslateText.CallKeepScreenOn]: `Håll skärmen igång och lämna inte denna webbsida`,
    [TranslateText.CallAreYouSureHangUp]: `Är du säker på att du vill lägga på?`,
    [TranslateText.CallConfirm]: `Ja, lägg på`,
    [TranslateText.CallCancel]: `Avbryt`,
    [TranslateText.CallConnecting]: `Ansluter...`,

    [TranslateText.LatchFunction]: `BRYTARE`,
    [TranslateText.TalkToAll]: `Tala till alla`,

    [TranslateText.VideoFitButton]: `SKALA`,

    [TranslateText.SessionEnd]: `Lämna`,
    [TranslateText.SessionStopWatching]: `Avsluta`,

    [TranslateText.OptionsLanguage]: `Språk`,
    [TranslateText.OptionsAudioInput]: `Mikrofon [Ljudingång]`,
    [TranslateText.OptionsAudioOutput]: `Hörlurar [Ljudutgång]`,
    [TranslateText.OptionsAudioFeatures]: `Ljudfunktioner`,
    [TranslateText.OptionsNoOptionsAvailable]: `Inga inställningar tillgängliga för din enhet`,

    [TranslateText.ThankYouForJoiningSession]: `Tack för din medverkan!`,
    [TranslateText.ThanksReconnect]: `Återanslut`,

    [TranslateText.ErrorBrowserNotSupported]: `Din webbläsare stöds tyvärr inte`,
    [TranslateText.ErrorInvalidLink]: `Felaktig länk`,
    [TranslateText.ErrorNotAllowed]: `Kan inte verifiera länken, giltigheten kan ha passerats`,
    [TranslateText.ErrorNetworkConfigurationMissing]: `Länken fungerar inte, den kan ha gått ut`,
    [TranslateText.ErrorMicPermissionRequired]: `Tillåtelse att använda mikrofonen behövs`,
    [TranslateText.ErrorMediaProblem]: `Generellt media fel`,

// TODO: svenska.

    [TranslateText.MediaNotFoundError]: `Could not find a microphone or soundcard. Please make sure it's not disabled or not properly plugged in.`,
    [TranslateText.MediaNotReadableError]: `Could not access the selected media. The microphone / webcamera might be in use by another process or hardware error.`,
    [TranslateText.MediaOverconstrainedError]: `The requested device could not meet the constraints criteria.`,
    [TranslateText.MediaNotAllowedError]: `Not allowed to use the microphone or webcam. Please allow access.`,
    [TranslateText.MediaTypeError]: `Requested constraints for audio and video is empty or false. No media available.`,
    [TranslateText.MediaAbortError]: `We have access to media device and no hardware error, but something prevented us from using it. Please see detailed error message.`,
    [TranslateText.MediaSecurityError]: `User media access is disabled for this user-agent.`,
    [TranslateText.MediaPermissionDismissedError]: `Privacy dialog was closed when asking for media permission.`,
    [TranslateText.MediaUnknown]: `Unknown media error occured.`,

    [TranslateText.ErrorInfoUseSafariInstead]: `Använd Safari istället`,

    [TranslateText.ErrorMicAccessFailed]: `Webbläsaren verkar inte ha tillgång till din mikrofon`,

    [TranslateText.ErrorMicAccessChrome1]: `En kamerasymbol med rött kryss finns i adressfältet till höger. Klicka på den och tillåt mikrofonanvändning`,
    [TranslateText.ErrorMicAccessChrome2]: `Ladda om sidan med F5`,
    [TranslateText.ErrorMicAccessFireFox1]: `För att ge tillåtelse att använda mikrofonen, klicka på den överkryssade mikrofonsymbolen i adressfältet till vänster`,
    [TranslateText.ErrorMicAccessFireFox2]: `Klicka sedan på krysset som indikerar att mikrofon är blockerad. Finns det ingen symbol i adressfältet behöver du gå in i inställningar för webbläsaren genom att klicka på de tre strecken i övre högra hörnet`,
    [TranslateText.ErrorMicAccessFireFox3]: `Klicka på "Inställningar" och välj sedan "Sekretess och säkerhet"`,
    [TranslateText.ErrorMicAccessFireFox4]: `Under "Rättigheter", klicka på "Inställningar" för mikrofonen och tillåt webbsidan som används för intervjun att använda mikrofonen`,
    [TranslateText.ErrorMicAccesstEdge1]: `En mikrofonsymbol med rött kryss finns i adressfältet till höger. Klicka på den och tillåt mikrofonanvändning`,
    [TranslateText.ErrorMicAccesstEdge2]: `Ladda om sidan med F5`,
    [TranslateText.ErrorMicAccessSafari1]: `Om du fick frågan om mikrofonen men nekade, stäng fönstret och öppna länken igen, svara "Tillåt"`,
    [TranslateText.ErrorMicAccessSafari2]: `Får du ingen fråga om åtkomst till mikrofon måste du klicka på "Safari" i övre vänsta hörnet, välja "Inställningar för den här webbplatsen" och tillåta mikrofonen`,
    [TranslateText.ErrorMicAccessSafari3]: `Ladda sedan om sidan`,

    [TranslateText.ErrorMicAccessChromeMobile1]: `Om du råkade blockera användning av mikrofonen. Stäng webbsidan, öppna inbjudningslänken igen och tryck på "Tillåt" på frågan om användning av mikrofon`,
    [TranslateText.ErrorMicAccessChromeMobile2]: `Om du inte får någon fråga, öppna webbläsarens inställningar genom att trycka på de tre punkterna i det övre högra hörnet. Öppna "Inställningar", välj "Webbplatsinställningar" och "Mikrofon"`,
    [TranslateText.ErrorMicAccessChromeMobile3]: `Tryck på adressen för webbsidan och välj "Tillåt". Om "Tillåt" redan är vald, välj "Ta bort"`,
    [TranslateText.ErrorMicAccessChromeMobile4]: `Stäng webbläsaren och öppna inbjudningslänken på nytt`,
    [TranslateText.ErrorMicAccessSafariMobile1]: `Om du fick frågan om mikrofonen men nekade, stäng fönstret och öppna länken igen, svara "Tillåt"`,
    [TranslateText.ErrorMicAccessSafariMobile2]: `Får du ingen fråga om åtkomst till mikrofon måste du gå in i "Inställningar" för telefonen och under Safari leta upp mikrofon och välj "Tillåt eller fråga"`,
    [TranslateText.ErrorMicAccessSamsungInternet1]: `Om du fick frågan om mikrofonen men nekade, tryck på de tre strecken i det nedre högra hörnet och välj "Inställningar"`,
    [TranslateText.ErrorMicAccessSamsungInternet2]: `Tryck sedan på "Personliga data" och välj "Radera webbläsardata"`,
    [TranslateText.ErrorMicAccessSamsungInternet3]: `Markera åtminstone "Data om åtkomst till plats och tryck på "Ta Bort"`,
    [TranslateText.ErrorMicAccessSamsungInternet4]: `Ladda om sidan`,

    [TranslateText.FooterLanguageHeader]: `Språkval`,
    [TranslateText.FooterLanguageDescription]: `Välj ett språk för att översätta sidan till`,

    [TranslateText.FooterServiceHeader]: `Tjänsten`,
    [TranslateText.FooterServiceDescription]: `Detta är en tjänst från Seqtalk. Möjliggör säker och enkel kommunikation över vanliga nätverk.`,
};

const en: TextTemplate = {
    [TranslateText.Welcome]: `Welcome!`,
    [TranslateText.WelcomeWhenYouPressStart]: `When you press start, you will be asked to allow the use of the microphone`,
    [TranslateText.WelcomeTipsForBestExperience]: `For the best audio quality and user experience:`,
    [TranslateText.WelcomeTipUseHeadset]: `Use a headset`,
    [TranslateText.WelcomeTipActivateDoNotDisturb]: `Activate "Do not disturb"-mode`,
    [TranslateText.WelcomeTipKeepScreenOn]: `Keep the screen on`,
    [TranslateText.WelcomeTipDontLeaveWebPage]: `Don't leave the web page`,
    [TranslateText.WelcomeStart]: `Start`,

    [TranslateText.WarningUserRefreshingPage]: `Do you wan't to refresh the page and leave the session?`,

    [TranslateText.CallHangUp]: `Leave session`,
    [TranslateText.CallMute]: `Mute`,
    [TranslateText.CallUnMute]: `Unmute`,
    [TranslateText.CallOptions]: `Options`,
    [TranslateText.CallYouAreFirstHere1]: `You're the only one currently in the session`,
    [TranslateText.CallYouAreFirstHere2]: `Don't leave this page while in session`,
    [TranslateText.CallInCallWith]: `Connected to`,
    [TranslateText.CallKeepScreenOn]: `Please keep the screen on don't leave this web page`,
    [TranslateText.CallAreYouSureHangUp]: `Are you sure?`,
    [TranslateText.CallConfirm]: `Yes, leave`,
    [TranslateText.CallCancel]: `No, stay`,
    [TranslateText.CallConnecting]: `Connecting...`,

    [TranslateText.LatchFunction]: `LATCH`,
    [TranslateText.TalkToAll]: `Talk all`,

    [TranslateText.VideoFitButton]: `SCALE`,

    [TranslateText.SessionEnd]: `End`,
    [TranslateText.SessionStopWatching]: `Leave`,

    [TranslateText.OptionsLanguage]: `Language`,
    [TranslateText.OptionsAudioInput]: `Microphone [Audio in]`,
    [TranslateText.OptionsAudioOutput]: `Speaker [Audio out]`,
    [TranslateText.OptionsAudioFeatures]: `Audio features`,
    [TranslateText.OptionsNoOptionsAvailable]: `No options available for your device`,

    [TranslateText.ThankYouForJoiningSession]: `Thank you for joining!`,
    [TranslateText.ThanksReconnect]: `Reconnect`,

    [TranslateText.ErrorBrowserNotSupported]: `Unfortunately, your browser is not supported`,
    [TranslateText.ErrorInvalidLink]: `Invalid link`,
    [TranslateText.ErrorNotAllowed]: `Can't verify the link, it may have expired`,
    [TranslateText.ErrorNetworkConfigurationMissing]: `The link is not working, it may have expired`,
    [TranslateText.ErrorMicPermissionRequired]: `Please accept permission to use the microphone`,
    [TranslateText.ErrorMediaProblem]: `General media error`,

    [TranslateText.MediaNotFoundError]: `Could not find a microphone or soundcard. Please make sure it's not disabled or not properly plugged in.`,
    [TranslateText.MediaNotReadableError]: `Could not access the selected media. The microphone / webcamera might be in use by another process or hardware error.`,
    [TranslateText.MediaOverconstrainedError]: `The requested device could not meet the constraints criteria.`,
    [TranslateText.MediaNotAllowedError]: `Not allowed to use the microphone or webcam. Please allow access.`,
    [TranslateText.MediaTypeError]: `Requested constraints for audio and video is empty or false. No media available.`,
    [TranslateText.MediaAbortError]: `We have access to media device and no hardware error, but something prevented us from using it. Please see detailed error message.`,
    [TranslateText.MediaSecurityError]: `User media access is disabled for this user-agent.`,
    [TranslateText.MediaPermissionDismissedError]: `Privacy dialog was closed when asking for media permission.`,
    [TranslateText.MediaUnknown]: `Unknown media error occured.`,

    [TranslateText.ErrorInfoUseSafariInstead]: `Use Safari instead`,

    [TranslateText.ErrorMicAccessFailed]: `Could not get access to the microphone`,

    [TranslateText.ErrorMicAccessChrome1]: `To allow microphone access, click on the camera symbol displayed on the right of the address bar and choose "Allow"`,
    [TranslateText.ErrorMicAccessChrome2]: `Use F5 to reload the page after allowing access`,
    [TranslateText.ErrorMicAccessFireFox1]: `To allow microphone access, click on the crossed out microphone icon to the left of the address bar`,
    [TranslateText.ErrorMicAccessFireFox2]: `Click on the X which indicates that the microphone has been blocked. If you cannot find any microphone icon in the address bar, navigate to the browser settings using the "hamburger menu" in the top right of the browser window`,
    [TranslateText.ErrorMicAccessFireFox3]: `Click on "Settings" and scroll down to "Privacy & Security"`,
    [TranslateText.ErrorMicAccessFireFox4]: `Scroll down to the settings for the microphone and allow the webpage used for the interview to access your microphone`,
    [TranslateText.ErrorMicAccesstEdge1]: `To allow microphone access, click on the camera symbol displayed on the right of the address bar and choose "Allow"`,
    [TranslateText.ErrorMicAccesstEdge2]: `Use F5 to reload the page after allowing access`,
    [TranslateText.ErrorMicAccessSafari1]: `If you accidentally blocked the site from accessing the microphone, close the browser window manually. Navigate back to the invitation link and click on it again. When asked to allow the use of the microphone choose "Allow"`,
    [TranslateText.ErrorMicAccessSafari2]: `If you do not get a pop up asking for access, navigate to the browser settings using the menu in the top left corner of the browser window. Navigate to "Website settings" and allow the microphone`,
    [TranslateText.ErrorMicAccessSafari3]: `Reload the web page`,

    [TranslateText.ErrorMicAccessChromeMobile1]: `If you accidentally blocked the microphone access, close the web page manually. Navigate back to the invitation link and click on it again. When asked to allow the use of the microphone choose "Allow"`,
    [TranslateText.ErrorMicAccessChromeMobile2]: `If you do not get a pop up asking for access, navigate to the browser settings using the dot menu in the top right of the browser window. Navigate to Settings, choose "Website settings" and "Microphone"`,
    [TranslateText.ErrorMicAccessChromeMobile3]: `Click on the address-bar of the web page and make sure that you have allowed the microphone access. If already allowed, remove the access`,
    [TranslateText.ErrorMicAccessChromeMobile4]: `Close the browser and try your invitation link again`,
    [TranslateText.ErrorMicAccessSafariMobile1]: `If you accidentally blocked the the microphone access, close the browser window manually. Navigate back to the invitation link and click on it again. When asked to allow the use of the microphone choose "Allow"`,
    [TranslateText.ErrorMicAccessSafariMobile2]: `If you do not get a pop up asking for access, navigate to your phone settings, then navigate to Safari, scroll down to "Website settings" and "Microphone" and make sure "Ask" or "Allow" are activated`,
    [TranslateText.ErrorMicAccessSamsungInternet1]: `If you accidentally blocked the microphone access, navigate to the browser settings using the hamburger menu in the bottom right corner`,
    [TranslateText.ErrorMicAccessSamsungInternet2]: `Choose "Settings" and "Personal data"`,
    [TranslateText.ErrorMicAccessSamsungInternet3]: `Choose "Delete browsing data" and mark at least "Location access data" and choose "Remove"`,
    [TranslateText.ErrorMicAccessSamsungInternet4]: `Reload the web page`,

    [TranslateText.FooterLanguageHeader]: `Language choice`,
    [TranslateText.FooterLanguageDescription]: `Choose a language to translate the site to`,

    [TranslateText.FooterServiceHeader]: `Service`,
    [TranslateText.FooterServiceDescription]: `Preview Pilot from Entercast`,
};

const translations: { [K in Language]: TextTemplate } = {
    [Language.Swedish]: sv,
    [Language.English]: en,
};

export const getTranslation = (message: TranslateText, language: Language): string => {
    return translations[language][message];
};
