import React from "react";
import ReactDOM from "react-dom/client";

// import { createRoot } from 'react-dom/client';
// import App from "./components/App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GlobalState } from "./utils/globalState";

// const container = document.getElementById("root");
// const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

function importBuildTarget() {
    if (process.env.REACT_APP_CLIENT_KIND === "entercast") {
        return import("./components/entercast/EntercastApp");
    } else if (process.env.REACT_APP_CLIENT_KIND === "rsys") {
        return import("./components/App");
    } else {
        return Promise.reject(
            new Error("No such build target: " + process.env.REACT_APP_CLIENT_KIND)
        );
    }
}

importBuildTarget().then(({ default: Environment}) => {
    root.render(
        <React.StrictMode>
            <GlobalState>
                <Environment />
            </GlobalState>
        </React.StrictMode>
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
