import { assertUnreachable } from "../utils/assertUnreachable";
import { Logger } from "../utils/logger";

const SWEDISH_LANGUAGE_CODE = "sv";

export enum Language {
    Swedish,
    English,
}

export enum LanguageShortCode {
    Swedish = "se",
    English = "en"
}

export const languages: Language[] = [Language.Swedish, Language.English];
export const languagesShortCode: LanguageShortCode[] = [LanguageShortCode.Swedish, LanguageShortCode.English];

export const detectBrowserLanguage = () => {
    // Detect if browser language is set to swedish
    if (navigator.language?.includes(SWEDISH_LANGUAGE_CODE) === true) {
        Logger.debug(`Language: Detected browser language swedish`);
        // return Language.Swedish; // TODO: reason why???
    }

    // Fallback to english
    Logger.debug(`Language: Fallback to english`);
    return Language.English;
};

export const getLanguageText = (language: Language) => {
    switch (language) {
        case Language.Swedish:
            return "Svenska";
        case Language.English:
            return "English";
        default:
            assertUnreachable(language);
    }
};
